.Frames {
    width: 40%;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0);
    align-items: center;
}

.Frames.night{
    display:none;
}
.Frames.day{
    display:flex;
}

.frame {
    margin-left: 5px;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.25));
}

#frame-5 {
    margin-top: 5px;
    transform-origin: 50% 0%;
    transform: rotate(-10deg);
    cursor: pointer;
    filter: drop-shadow(0 0 5px rgba(0, 0, 255, 0.75));
}

#frame-5.clicked {
    animation: tiltback 3s forwards;
    cursor: default;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.25));
}

@keyframes tiltback {
    from {
        transform: rotate(-10deg);
    }

    to {
        transform: rotate(0deg);
    }
}