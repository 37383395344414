.Table {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 50%;
    bottom: -10px;
    flex-direction: column;
    align-items: center;
}

.Table.day {
    display: flex;
}

.Table.night {
    display: none;
}

.blue-ball {
    background-color: #8180FF;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20%;
    bottom: 30%;
    z-index: 1;
    box-shadow: -2px -2px 2px inset rgba(0, 0, 0, 0.15), 2px 2px 2px inset rgba(255, 255, 255, 0.15);
}

.blue-ball::after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 100%;
    width: 200%;
    height: 100%;
    transform-origin: top left;
    transform: rotate(45deg);
    background-color: rgba(0, 0, 0, 0.25);
    filter: blur(7px);
    border-radius: 50%;
    z-index: -1;
}

