.App {
  height: 100vh;
  background-color: var(--night);
}

#too-small {
  display: none;
}

.App.day {
  background-color: var(--day);
}

img, svg {
  width: 100%;
}

.glow {
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, .60));
}

.JassarDev {
  font-weight: bold;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  letter-spacing: .5em;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}

.JassarDev.day {
  color: #C0C0C0;
  opacity: 30%;
}

.JassarDev span {
  font-size: 0.5em;
}

#container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  overflow: hidden;
}

@media screen and (max-width: 1100px) {
  #container {
    display: none;
  }

  #too-small {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    width: 40%;
    text-align: center;
  }

  #too-small h1 {
    font-size: 2em;
  }
}